import React from "react";
import { graphql } from "gatsby";
import DarkenedBackgroundImage from "../components/darkened-background-image";
import classNames from "classnames";

import Layout from "../components/layout";
import SEO from "../components/seo";
import PatternContainer from "../components/pattern-container";
import Location from "../components/location";
import LocationBadge from "../components/location-badge";

function LocationsPage({ data }) {
  const {
    seo_description,
    seo_keywords,
    headline,
    headline_background,
    more_locations_title,
    more_locations_subtitle
  } = data.prismicLocationsPage.data;

  return (
    <Layout>
      <SEO
        title={headline}
        keywords={seo_keywords?.split(",").map(keyword => keyword.trim())}
        description={seo_description}
      />
      <DarkenedBackgroundImage
        Tag="section"
        className="flex flex-col items-center p-4 text-white pt-32 pb-32 md:pb-64"
        image={headline_background.gatsbyImageData}
      >
        <h1>{headline}</h1>
      </DarkenedBackgroundImage>
      <PatternContainer className="p-4 md:pt-0 md:-mt-40">
        <Locations locations={data.fullLocations.nodes} />
        <LimitedLocations
          title={more_locations_title}
          subtitle={more_locations_subtitle}
          locations={data.limitedLocations.nodes}
          className="mt-8"
        />
      </PatternContainer>
    </Layout>
  );
}

function Locations({ locations }) {
  return (
    <section className="flex flex-col max-w-6xl mx-auto">
      {locations.map(location => (
        <Location
          key={location.iid}
          locationData={location.data}
          uid={location.uid}
          className="bg-white text-rosens-green-kelp max-w-6xl items-center mt-8 md:z-10"
          allowSideBySide
        />
      ))}
    </section>
  );
}

function LimitedLocations({ locations, title, subtitle, className }) {
  return (
    <section
      className={classNames(
        "bg-white text-rosens-green-kelp p-4 md:py-16 mx-auto max-w-6xl",
        className
      )}
    >
      <h2>{title}</h2>
      <p className="text-center mt-2">{subtitle}</p>
      <div className="flex flex-wrap mt-16">
        {locations.map(location => (
          <LocationBadge
            key={location.id}
            locationData={location.data}
            showDetails
            className="mb-12 w-1/2 md:w-1/3"
          />
        ))}
      </div>
    </section>
  );
}

export const query = graphql`
  query LocationsPageQuery {
    prismicLocationsPage {
      data {
        seo_description
        seo_keywords
        headline
        headline_background {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, height: 400)
        }
        more_locations_title
        more_locations_subtitle
      }
    }
    fullLocations: allPrismicLocations(
      filter: { data: { type: { eq: "Full" } } }
    ) {
      nodes {
        uid
        data {
          image {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
          address1
          address2
          addresslink {
            url
          }
          hours
          name
          phone
          online_ordering_link {
            url
          }
          menu_button_label
          menupdf {
            url
          }
          temporarily_closed
        }
      }
    }
    limitedLocations: allPrismicLocations(
      filter: { data: { type: { eq: "Limited" } } }
      sort: { fields: data___name }
    ) {
      nodes {
        id
        data {
          image {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
          address1
          address2
          addresslink {
            url
          }
          hours
          name
          phone
          temporarily_closed
        }
      }
    }
  }
`;

export default LocationsPage;
