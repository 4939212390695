import React from "react";
import classNames from "classnames";
import formatPhone from "../utils/format-phone";
import { GatsbyImage } from "gatsby-plugin-image";

function LocationBadge({ locationData, className, showDetails = false }) {
  return (
    <article
      className={classNames(
        "text-center flex flex-col items-center",
        className
      )}
    >
      {locationData.image.gatsbyImageData && (
        <GatsbyImage
          image={locationData.image.gatsbyImageData}
          className="w-24 h-24 md:w-40 md:h-40 object-cover rounded-full"
        />
      )}
      <h3 className="font-bold text-lg mt-4 font-proxima-nova">
        {locationData.name}
      </h3>
      {showDetails && (locationData.address1 || locationData.phone) && (
        <p className="mt-2 hidden md:block text-base font-proxima-nova text-rosens-dove-gray text-center leading-relaxed tracking-wider">
          {locationData.address1 &&
            (locationData.addresslink ? (
              <a className="underline" href={locationData.addresslink.url}>
                {locationData.address1}
                <br />
                {locationData.address2}
              </a>
            ) : (
              <span>
                {locationData.address1}
                <br />
                {locationData.address2}
              </span>
            ))}
          <br />
          {locationData.phone && (
            <a className="underline" href={`tel:${locationData.phone}`}>
              {formatPhone(locationData.phone)}
            </a>
          )}
        </p>
      )}
      <p className="mt-2 font-proxima-nova text-center text-base text-rosens-dove-gray leading-relaxed tracking-wider whitespace-pre-wrap">
        {locationData.temporarily_closed ? (
          <span className="font-semibold">TEMPORARILY CLOSED</span>
        ) : (
          locationData.hours
        )}
      </p>
    </article>
  );
}

export default LocationBadge;
